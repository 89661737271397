import React from "react"
import Helmet from "react-helmet"

export class Helmety extends React.Component {
	render() {
	const {title, content, imgURL, url} = this.props;

		return (
			<Helmet>
				{/* <!-- Primary Meta Tags --> */}
				<title>{title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="title" content={title} />
				<meta name="description" content={content} />
				{/* <!-- Open Graph / Facebook --> */}
				<meta property="og:type" content="website" />
				<meta property="og:url" content={url} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={content} />
				<meta property="og:image" content={imgURL} />
				{/* <!-- Twitter --> */}
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content={url} />
				<meta property="twitter:title" content={title} />
				<meta property="twitter:description" content={content} />
				<meta property="twitter:image" content={imgURL} />
			</Helmet>
		);
	}
}

export default (title = "Dharma Books + Publishing", content, imgURL) =>
	<Helmet>
		{/* <!-- Primary Meta Tags --> */}
		<title>{title}</title>
		<meta name="title" content={title} />
		<meta name="description" content={content} />
		{/* <!-- Open Graph / Facebook --> */}
		<meta property="og:type" content="website" />
		<meta property="og:url" content="https://www.dharmabooks.com.mx/" />
		<meta property="og:title" content={title} />
		<meta property="og:description" content={content} />
		<meta property="og:image" content={imgURL} />
		{/* <!-- Twitter --> */}
		<meta property="twitter:card" content="summary_large_image" />
		<meta property="twitter:url" content="https://www.dharmabooks.com.mx/" />
		<meta property="twitter:title" content={title} />
		<meta property="twitter:description" content={content} />
		<meta property="twitter:image" content={imgURL} />
	</Helmet>

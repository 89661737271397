import React from "react";
import {Link, StaticQuery, graphql} from "gatsby";

import {MainLayout} from "../components/layout"
import {SectionHeader} from "./index";

import autorStyle from "../styles/autor.module.styl";
import shared from "../styles/shared.module.styl";

import bookImg from "../images/portadas/600x897.png"
import enriqueUrbina from "../images/autores/enrique_urbina.jpg"
import intiGarciaSantamaria from "../images/autores/inti_garcia_santamaria.jpg"
import juanAlcantara from "../images/autores/juan_alcantara.jpg";
import luisResendiz from "../images/autores/luis_resendiz.png";
import martinEspada from "../images/autores/marin_espada.jpg";
import miguelCossio from "../images/autores/miguel_cossio.jpg";
import rosaDuran from "../images/autores/rosa_duran.jpg";
import saraUribe from "../images/autores/sara_uribe.jpg";
import andreaAlzati from "../images/autores/andrea_alzati.jpg";
import antonioOrtuno  from "../images/autores/antonio_ortuno.jpg";
import karlaZarate from "../images/autores/karla_zarate.jpg";
import arianaHarwicz from "../images/autores/ariana_harwicz.jpg";
import lilianLopezCamberos from "../images/autores/lilian_lopez_camberos.jpg";
import loreaCanales from "../images/autores/lorea_canales.jpg";
import lmOliveira from "../images/autores/luis_munos_oliveira.jpeg";
import miguelCane from "../images/autores/miguel_cane.jpg";
import rogerSantivanez from "../images/autores/roger_santivanez.jpg";
import jaimeMesa from "../images/autores/jaime_mesa.jpg";
import davidMiklos from "../images/autores/david_miklos.jpg";
import pauletteJonguitud from "../images/autores/paulette_jonguitud.jpg";
import ingridSolana from "../images/autores/ingrid_solana.jpg";
import rafaelFerrer from "../images/autores/rafael_ferrer.jpeg";
import rodrigoDiez from "../images/autores/rodrigo_diez.jpeg";
import luciaMaria from "../images/autores/lucia-maria.png";
import fernandaTrias from "../images/autores/fernanda-trias.png";
import brunoLloret from "../images/autores/bruno-lloret.png";
import boscoSodi from "../images/autores/bosco_sodi.png";
import danielEspartaco from "../images/autores/daniel_espartaco.jpg";
import patriciaArredondo from "../images/autores/patricia_arredondo.jpeg";
import marcelaSantos from "../images/autores/marcela_santos.png";
import alejandraMaldonado from "../images/autores/alejandra_maldonado.jpg";
import draupadiDeMora from "../images/autores/draupadi_de_mora.jpeg";
import fani from "../images/autores/estefania_arista.jpeg";
import mikaelGomez from "../images/autores/mikael_gomez.png";
import cristinaRiveraGarza from "../images/autores/cristina_rivera_garza.jpg";
import nataliaGalvez from "../images/autores/natalia_galvez.png";
import marinaCloss from "../images/autores/marina_closs.png";
import franciscoCarillo from "../images/autores/francisco_carrillo.png";
import ingridValencia from "../images/autores/ingrid_valencia.png";
import oliviaTeroba from "../images/autores/olivia_teroba.png";
import habacucAntonioDeRosario from "../images/autores/habacuc_antonio.png";
import jorgeCastellanos from "../images/autores/jorge_castellanos.png";
import wallaceStevens from "../images/autores/wallace_stevens.png";
import diegoFonseca from "../images/autores/diego_fonseca.png";
import pereCalders from "../images/autores/pere_calders.png";
import mariaJoseFerrada from "../images/autores/maria_jose_ferrada.png";
import diazAlanis from "../images/autores/isabel_diaz_alanis.png";
import marielIribe from "../images/autores/mariel-iribe-zenil.png";
import sebastianGomez from "../images/autores/sebastian_gomez_matus.png";
import ivethLunaFlores from "../images/autores/iveth_luna_flores.png";
import mariaCristinaHall from "../images/autores/maria_cristina_hall.png";
import chantalF from "../images/autores/chantal_flores.png";

import { Helmety } from "../components/Head";

export const AUTOR_IMG_MAP = {
  "Antonio Ortuño": antonioOrtuno,
  "Ariana Harwicz": arianaHarwicz,
  "Karla Zárate": karlaZarate,
  "Lilián López Camberos": lilianLopezCamberos,
  "Lorea Canales": loreaCanales,
  "L. M. Oliveira": lmOliveira,
  "Miguel Cane": miguelCane,
  "Roger Santiváñez": rogerSantivanez,
  "Andrea Alzati": andreaAlzati,
  "Enrique Urbina": enriqueUrbina,
  "Inti García Santamaría": intiGarciaSantamaria,
  "Juan Alcántara": juanAlcantara,
  "Luis Reséndiz": luisResendiz,
  "Martín Espada": martinEspada,
  "Miguel Cossío Woodward": miguelCossio,
  "Rosa Durán": rosaDuran,
  "Sara Uribe": saraUribe,
  "Jaime Mesa": jaimeMesa,
  "David Miklos": davidMiklos,
  "Paulette Jonguitud": pauletteJonguitud,
  "Ingrid Solana": ingridSolana,
  "Rafael Ferrer": rafaelFerrer,
  "Rodrigo Díez": rodrigoDiez,
  "Bruno Lloret": brunoLloret,
  "Lucía María": luciaMaria,
  "Fernanda Trías": fernandaTrias,
  "Bosco Sodi": boscoSodi,
  "Daniel Espartaco": danielEspartaco,
  "Patricia Arredondo": patriciaArredondo,
  "Marcela Santos": marcelaSantos,
  "Alejandra Maldonado": alejandraMaldonado,
  "Draupadí de Mora": draupadiDeMora,
  "Estefanía Arista": fani,
  "Mikaël Gómez Guthart": mikaelGomez,
  "Cristina Rivera Garza": cristinaRiveraGarza,
  "Natalia Gálvez": nataliaGalvez,
  "Marina Closs": marinaCloss,
  "Francisco Carrillo": franciscoCarillo,
  "Ingrid Valencia": ingridValencia,
  "Olivia Teroba": oliviaTeroba,
  "Habacuc Antonio De Rosario": habacucAntonioDeRosario,
  "Jorge Castellanos": jorgeCastellanos,
  "Pere Calders": pereCalders,
  "Diego Fonseca": diegoFonseca,
  "Wallace Stevens": wallaceStevens,
  "María José Ferrada": mariaJoseFerrada,
  "Isabel Díaz Alanís": diazAlanis,
  "Mariel Iribe Zenil": marielIribe,
  "Sebastián Gómez Matus": sebastianGomez,
  "Iveth Luna Flores": ivethLunaFlores,
  "María Cristina Hall": mariaCristinaHall,
  "Chantal Flores": chantalF
};

export const AuthorListQuery = graphql`
query AutoresQuery {
  allAutoresJson(sort: {fields: name}) {
    nodes {
      name
      about
      url
    }
  }
}
`;


export default class Autores extends React.Component {
  render () {

    return (
      <MainLayout>
        <Helmety
          url="https://www.dharmabooks.com.mx/autores/"
          title="Nuestros autores - Dharma Books"
          content="Estos son los autores de nuestra casa editorial"
          imgURL="https://www.dharmabooks.com.mx/static_resources/otros/autores.png"
        />
        <div className={shared.content}>
          <div className={shared.container}>
            <SectionHeader header="Autores" />
            <div className={`${autorStyle.autorGrid}`}>
              <StaticQuery
                query={AuthorListQuery}
                render={data => {
                  const {allAutoresJson} = data;
                  const authorList = allAutoresJson.nodes.map(autor => {
                    const img = AUTOR_IMG_MAP[autor.name] ? AUTOR_IMG_MAP[autor.name] : bookImg;

                    return (<div className={autorStyle.autor}>
                      <div className={autorStyle.autor__foto}>
                        <Link to={`/autor/${autor.url}/`}><img src={img} alt={autor.name} /></Link>
                      </div>
                      <Link to={`/autor/${autor.url}/`}>
                        <h1 className={autorStyle.autor__name}>{autor.name}</h1>
                      </Link>

                    </div>);
                  });
                  return authorList;
                }}
              />
            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
}


import React from "react"

import {
Helmety
} from "../components/Head";

import {MainLayout} from "../components/layout"
import {BookGrid, HorizontalBookGrid} from "../components/book_grids";

// CSS Modules
import home from "../styles/home.module.styl"
import shared from "../styles/shared.module.styl"
import bookStyles from "../styles/book.module.styl"

export class SectionHeader extends React.Component {
  render() {
    const {header} = this.props;
    return (
      <h1 className={home.sectionHeader}>{header}</h1>
    );
  }
}

export class SectionSubHeader extends React.Component {
  render() {
    const {header} = this.props;
    return (
      <h2 className={home.sectionSubheader}>{header}</h2>
    );
  }
}

export default class IndexPage extends React.Component {
  render() {
    return (
      <MainLayout siteTitle="Home - Dharma Books">
        <Helmety
          url="https://www.dharmabooks.com.mx/"
          title="Dharma Books + Publishing"
          content="Dharma Books es una editorial que quiere crecer a la par de lo que la literatura y la poesía nos lo permitan."
          imgURL="https://www.dharmabooks.com.mx/static_resources/otros/stand_fil_19_2.jpg"
        />

        <div className={shared.container}>
          <br/>
          <SectionHeader header="Lo más nuevo" />
        </div>
        <BookGrid gridSizeClass={bookStyles.bigGrid} />

        <div className={shared.container}>
          <SectionHeader header="Lo más leído" />
        </div>
        <HorizontalBookGrid />

      </MainLayout>
    );
  }
}

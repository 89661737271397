import React from "react"


export function withShopifyBuyButton(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const {pageContext, shopifyId} = this.props;
      const {book} = pageContext;

      const finalShopifyId = book && book.shopifyId ? book.shopifyId : shopifyId;

      this.state ={
        shopifyId: finalShopifyId
      };
    }

    static defaultProps = {
      pageContext: {
        book: {}
      },
      shopifyId: null
    };

    componentDidMount() {
      const {pageContext, shopifyId: shopifyIdFromProps} = this.props;
      const {book} = pageContext;
      const shopifyId = book && book.shopifyId ? book.shopifyId : shopifyIdFromProps;

      if (shopifyId) {
        if (window.ShopifyBuy) {
          if (window.ShopifyBuy.UI) {
            this.ShopifyBuyInit();
          } else {
            this.loadScript();
          }
        } else {
          this.loadScript();
        }
      }
    }

    loadScript() {
      const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = this.ShopifyBuyInit;
    }

    ShopifyBuyInit = () => {
      const {pageContext, shopifyId: shopifyIdFromProps} = this.props;
      const {book} = pageContext;
      const shopifyId = book && book.shopifyId ? book.shopifyId : shopifyIdFromProps;

      let client = window.ShopifyBuy.buildClient({
        domain: 'dharma-books-publishing.myshopify.com',
        storefrontAccessToken: 'd059ed8cd9f469938ec83ea5fe71c9a5',
      });
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        const btn = `shopify-btn-catalogo-${shopifyId}`;
        ui.createComponent('product', {
          id: shopifyId,
          node: document.getElementById(btn),
          moneyFormat: '%24%20%7B%7Bamount%7D%7D',
          options: {
            "product": {
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px"
                  }
                },
                "button": {
                  "font-family": "Quantico, sans-serif",
                  "font-weight": "bold",
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px",
                  ":hover": {
                    "background-color": "#dab120"
                  },
                  "background-color": "#f2c524",
                  ":focus": {
                    "background-color": "#dab120"
                  },
                  "border-radius": "0px"
                },
                "quantityInput": {
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px"
                }
              },
              "contents": {
                "img": false,
                "title": false,
                "price": false
              },
              "text": {
                "button": "Agregar al carrito",
                "outOfStock": "No disponible",
                "unavailable": "No disponible"
              },
              "googleFonts": [
                "Quantico"
              ]
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            "modalProduct": {
              "contents": {
                "img": false,
                "imgWithCarousel": true,
                "button": false,
                "buttonWithQuantity": true
              },
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px"
                  }
                },
                "button": {
                  "font-family": "Raleway, sans-serif",
                  "font-weight": "bold",
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px",
                  ":hover": {
                    "background-color": "#dab120"
                  },
                  "background-color": "#f2c524",
                  ":focus": {
                    "background-color": "#dab120"
                  },
                  "border-radius": "0px"
                },
                "quantityInput": {
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px"
                }
              },
              "googleFonts": [
                "Raleway"
              ],
              "text": {
                "button": "Add to cart"
              }
            },
            "cart": {
              "styles": {
                "button": {
                  "font-family": "Raleway, sans-serif",
                  "font-weight": "bold",
                  "font-size": "14px",
                  "padding-top": "15px",
                  "padding-bottom": "15px",
                  ":hover": {
                    "background-color": "#dab120"
                  },
                  "background-color": "#f2c524",
                  ":focus": {
                    "background-color": "#dab120"
                  },
                  "border-radius": "0px"
                }
              },
              "text": {
                "total": "Subtotal",
                "button": "Checkout"
              },
              "googleFonts": [
                "Raleway"
              ]
            },
            "toggle": {
              "styles": {
                "toggle": {
                  "font-family": "Raleway, sans-serif",
                  "font-weight": "bold",
                  "background-color": "#f2c524",
                  ":hover": {
                    "background-color": "#dab120"
                  },
                  ":focus": {
                    "background-color": "#dab120"
                  }
                },
                "count": {
                  "font-size": "14px"
                }
              },
              "googleFonts": [
                "Raleway"
              ]
            }
          },
        });
      });
    };

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

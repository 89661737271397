import React from "react"
import { Link } from "gatsby"

// CSS Modules
import bookStyles from "../styles/book.module.styl"

import {AmazonIcon, StopwatchIcon } from "../components/fa"

import bookImg from "../images/portadas/600x897.png"

export class BuyBook extends React.Component {
  render() {
    const {amazon} = this.props;

    return (
      <div className={bookStyles.book__buy}>
        {amazon ? <AmazonIcon to={amazon}/> : ""}
      </div>
    );
  }
}

export class Book extends React.Component {
  static defaultProps = {
    purchaseLinks: {
      amazon: "/"
    },
    url: "",
    special: false,
    showOverlay: true
  };

  render() {
    const {title, author, author2, img, url, released, author2Url, authorUrl, special, showOverlay, launchDate, shopifyId} = this.props;
    const isReleased = released !== false;

    return (
      <div className={`${bookStyles.book__container} ${isReleased ? "" : bookStyles.book__unreleased}`}>
        <div className={`${showOverlay ? bookStyles.book : bookStyles.bookNoOverlay}`}>
          {showOverlay ? "" : <p className={bookStyles.bookNoOverlay__launchDate}>{launchDate}</p>}
          {isReleased ?
            <Link to={special ? url : `/libros/${url}`} className={bookStyles.book__coverLink}>
              {img ?
                <img alt={title}
                     className={bookStyles.book__cover}
                     src={img} /> :
                <div className={bookStyles.bookPlaceholder}>
                  <img alt={title} className={bookStyles.book__cover} src={bookImg} />
                </div>
              }
            </Link> :
            <Link to={special ? url : `/libros/${url}`} className={bookStyles.book__coverLink}>
              {img ?
                <img alt={title} className={`${bookStyles.book__cover} ${isReleased ? "" : bookStyles.book__coverUnreleased}`}
                     src={img} /> :
                <div className={bookStyles.bookPlaceholder}>
                  <img alt={title} className={`${bookStyles.book__cover} ${isReleased ? "" : bookStyles.book__coverUnreleased}`}
                       src={bookImg} />
                </div>
              }

            </Link>
          }

          {showOverlay ?
            <div className={bookStyles.book__data}>
              {isReleased ?
                <React.Fragment>
                  <Link to={special ? url : `/libros/${url}`}><h2>{title}</h2></Link>
                  {authorUrl ?
                    <Link to={special ? url : `/autor/${authorUrl}`}><h3>{author}</h3></Link> :
                    <h3>{author}</h3>}
                  {author2Url ?
                    <Link to={special ? url : `/autor/${author2Url}`}><h3>{author2}</h3></Link> :
                    <h3>{author2}</h3>}
                </React.Fragment> :
                <React.Fragment>
                  <h2>{title}</h2>
                  <h3>{author}
                    {author2 && '/'}
                    {author2 && author2}
                  </h3>
                  <span className={bookStyles.unreleasedRibbon}><StopwatchIcon /> Próximamente</span>
                </React.Fragment>
              }
            </div> :
            <div className={bookStyles.bookNoOverlay__data}>
              <Link to={special ? url : `/libros/${url}`}><h2>{title}</h2></Link>
              {authorUrl ?
                <Link to={special ? url : `/autor/${authorUrl}`}><h3>{author}</h3></Link> :
                <h3>{author}</h3>}
              {author2Url ?
                <Link to={special ? url : `/autor/${author2Url}`}><h3>{author2}</h3></Link> :
                <h3>{author2}</h3>}
              <div className={bookStyles.bookNoOverlay__shopifyBtn} key={`shopify-btn-catalogo-${shopifyId}`} id={`shopify-btn-catalogo-${shopifyId}`} />
            </div>}
        </div>
      </div>
    );
  }
}

export class HorizontalLayoutBook extends React.Component {
  static defaultProps = {
    purchaseLinks: {
      amazon: "/"
    },
    url: ""
  };

  render() {
    const {title, author, author2, author2Url, purchaseLinks, img, blurb, leftCover, authorUrl, url} = this.props;
    const {amazon} = purchaseLinks;

    return (
      <div className={`${bookStyles.book__container} ${leftCover ? "" : bookStyles.reverse}`}>
        {leftCover ?
          <Link to={`/libros/${url}`} className={bookStyles.book__coverLink}>
            <img alt={title} className={bookStyles.book__cover} src={img} /></Link> : ""}
        <div className={bookStyles.book__data}>
          <Link to={`/libros/${url}`} ><h2>{title}</h2></Link>
          <Link to={`/autor/${authorUrl}`} ><h3>{author}</h3></Link>
          <p>{blurb}</p>
          <div className={bookStyles.book__buy}>
            {amazon ? <AmazonIcon to={amazon}/> : ""}
          </div>
        </div>
        {leftCover ? "" :
          <Link to={`/libros/${url}`} className={bookStyles.book__coverLink}>
            <img alt={title} className={bookStyles.book__cover} src={img} /></Link>}
      </div>
    );
  }
}


import React from "react"

import {MainLayout} from "../components/layout"

// CSS Modules
import shared from "../styles/shared.module.styl"

import autorStyle from "../styles/autor.module.styl";
import bookImg from "../images/portadas/600x897.png";
import { AUTOR_IMG_MAP } from "../pages/autores";
import {
  BookPerAuthorGrid
} from "../components/book_grids";
import { Helmety } from "./Head";

export default class AutorDetailPage extends React.Component {
  render() {
    const {pageContext} = this.props;
    const {autor} = pageContext;
    const img = autor && AUTOR_IMG_MAP[autor.name] ? AUTOR_IMG_MAP[autor.name] : bookImg;

    return (
      <MainLayout>
        <Helmety
          url={`https://www.dharmabooks.com.mx/autor/${autor.url}`}
          title={`${autor.name} en Dharma Books`}
          content={`${autor.about}`}
          imgURL={img}
        />
        <div className={shared.container}>
          <div className={shared.content}>
            {autor ?
            <React.Fragment>
              <div className={autorStyle.autorDetail}>
                <div className={autorStyle.autorDetail__foto}>
                  <img src={img} alt={autor.name}/>
                </div>
                <h1>{autor.name}</h1>
                <p>{autor.about}</p>
              </div>
              <div className={autorStyle.autorBooks}>
                <BookPerAuthorGrid author={autor.name}
                  authorUrl={autor.url} />
              </div>
            </React.Fragment> : ""}
          </div>
        </div>
      </MainLayout>
    );
  }
}
